const routeConstant = {
  signin: "signin",
  forgetPassword: "forget-password",
  signup: "signup",
  dashboard: "/",
  accountActivate: "/account/activate/:id/:code",
  bookingSuccess: "/booking/billing/success/:code/:bookingId",
};

export default routeConstant;
