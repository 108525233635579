import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { planModalData } from "../utils/constants.js";
import Cookies from "js-cookie";
import useFetch from "../api/HttpRequest.js";
import { useNavigate } from "react-router-dom";

const UpgradePlanModal = ({
  isUpgradePlanModalOpen,
  setIsUpgradePlanModalOpen,
}) => {
  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');

  const { data: subscriptionResponse, fetchData: fetchSubscriptionAdd } = useFetch('/subscription/add', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  const handleClose = () => {
    setIsUpgradePlanModalOpen(false);
  };

  const handleEnterpriseClick = () => {
    if (accessToken) {
      fetchSubscriptionAdd();
    } else {
      navigate('/signin');
    }
  }

  useEffect(() => {
    if (subscriptionResponse?.status) {
      window.location.href = subscriptionResponse.data.payment_link;
    }
  }, [subscriptionResponse]);

  return (
    <Modal
      open={isUpgradePlanModalOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isUpgradePlanModalOpen}>
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-[1000px] shadow-lg p-4 flex flex-col">
          <Typography className="bg-slate-800 !text-[20px] md:!text-[30px] !font-extrabold !text-white !px-4 !py-6 !rounded-t-lg flex justify-between items-center">
            Upgrade Your Plan
            <CloseIcon className="cursor-pointer" onClick={handleClose} />
          </Typography>
          <Box className="bg-gray-900 flex flex-col md:flex-row gap-2 min-h-[400px] !rounded-b-lg">
            {planModalData.map(
              ({ type, description, price, priceDescription, buttonText, chatGptVersion, features }, index) => (
                <Box
                  key={type}
                  className={`flex flex-1 flex-col justify-center gap-7 p-4 items-center ${
                    index !== 2 && "md:border-r border-gray-600"
                  } ${index !== 0 && "border-t md:border-t-0 border-gray-600"}`}
                >
                  <Box className="flex flex-col items-center w-full gap-0">
                    <Typography className="!text-[20px] md:!text-[30px] !font-extrabold !text-white text-center">
                      {type}
                    </Typography>
                    <Typography className="!text-[14px] md:!text-[16px] !font-[550] !text-gray-400 text-center">
                      {description}
                    </Typography>
                  </Box>
                  <Box className="flex flex-col items-center w-full gap-0">
                    <Typography className="!text-[33px] !font-[550] !text-white">
                      ${price}
                    </Typography>
                    <Typography className="!text-[13px] !font-[550] !text-gray-400">
                      {priceDescription}
                    </Typography>
                  </Box>
                  <Box className="flex flex-col items-center w-full gap-2 text-center">
                    {features.map((feature, idx) => (
                      <Typography 
                        key={idx} 
                        className="!text-[14px] !text-gray-400"
                      >
                        {feature}
                      </Typography>
                    ))}
                  </Box>
                  <Button
                    className="flex justify-center !bg-[#00A67E] !text-white !text-[16px] !font-extrabold !capitalize py-3 !rounded-3xl cursor-pointer hover:!bg-[#57c1a8] !w-[90%]"
                    onClick={index === 0 ? handleClose : handleEnterpriseClick}
                  >
                    {buttonText}
                  </Button>
                  <Typography className="!text-[14px] !font-[550] !text-gray-400">
                    {chatGptVersion}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpgradePlanModal;
