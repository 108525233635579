import { Box, Button, CircularProgress, Typography, useMediaQuery, IconButton, Drawer, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import React, { useContext, useEffect, useRef, useState } from "react";
import routeConstant from "../utils/routeConstant";
import ChatInput from "../components/ChatInput";
import ChatModelDropdown from "../components/ChatModelDropdown";
import CustomLink from "../components/CustomLink";
import DrawerButtons from "../components/DrawerButtons";
import DrawerChatsList from "../components/DrawerChatsList";
import { UserContext } from "../providers/userProvider";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Logo from "../assets/image.png";
import UpgradePlanModal from "../components/UpgradePlanModal";
import { sendChatQuery, uploadPDF } from "../api/chat";
import Typewriter from "typewriter-effect";
import { modalArr } from "../utils/constants";
import FormData from "form-data";
import chatIcon from "../assets/logo.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Cookies from "js-cookie"; // Import js-cookie
import useFetch from "../api/HttpRequest";
import toast from "react-hot-toast";

import WarningIcon from '@mui/icons-material/Warning';

const Dashboard = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  // State Management
  const [model, setModel] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const [enteredQuery, setEnteredQuery] = useState("");
  const [attachment, setAttachment] = useState();
  const [attachedDoc, setAttachedDoc] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const lastRef = useRef(null);
  const lastInteractionIndexRef = useRef(null);

  const [interactions, setInteractions] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(Cookies.get('accessToken'));

  const [isDrawerChatsLoading, setIsDrawerChatsLoading] = useState(true);

  const [isChatLoading, setIsChatLoading] = useState(false);

  const bottomRef = useRef(null);

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    chatId: null
  });

  const [selectedPdfName, setSelectedPdfName] = useState("");

  const { fetchData: logoutFetch } = useFetch('/user/logout', {
    method: 'GET',
    silent: true,
  });

  const { data: questionData, fetchData: questionFetch } = useFetch('/chat/ask/question', {
    method: 'POST',
    accessToken,
    silent: true,
  });

  const { data: questionWithPDFData, fetchData: questionWithPDFFetch } = useFetch('/file/upload', {
    method: 'POST',
    accessToken,
    silent: true,
    contentType: 'multipart/form-data',
  });

  const { data: drawerChatsData, fetchData: fetchDrawerChats } = useFetch('/chat/list/by/user', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  const { data: chatQuestionsData, fetchData: fetchChatQuestions } = useFetch('/quesion/list/by/chat', {
    method: 'POST',
    accessToken,
    silent: true,
  });

  const { fetchData: deleteChat } = useFetch('/deleteData', {
    method: 'POST',
    accessToken,
    silent: true,
  });

  const [uploading, setUploading] = useState(false);
  const [fileSizeWarning, setFileSizeWarning] = useState(false);

  useEffect(() => {
    if (chatQuestionsData) {
      const newInteractions = chatQuestionsData.data.flatMap((item) => ([
        { role: "system", content: item.quesion, fromHistory: true },
        { role: "assistant", content: item.answer, fromHistory: true }
      ]));

      setInteractions(newInteractions);

      // Add scroll to bottom after chat history loads
      setTimeout(() => {
        lastRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100);
    }
  }, [chatQuestionsData]);

  let pdfBlob = new Blob([attachment], { type: "application/pdf" });
  const pdfFile = new File([pdfBlob], "file.pdf", { type: "application/pdf" });
  const formData = new FormData();
  formData.append("files", pdfFile, { type: "application/pdf" });

  const fileName = attachment ? attachment.name : "";

  useEffect(() => {
    if (user === null) {
      setRedirect("/");
    }
  }, [user]);

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
    }
  }, [redirect, navigate]);

  useEffect(() => {
    if (accessToken) {
      setIsDrawerChatsLoading(true);
      fetchDrawerChats()
        .finally(() => {
          setIsDrawerChatsLoading(false);
        });
    } else {
      setIsDrawerChatsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const scrollToBottom = () => {
    requestAnimationFrame(() => {
      lastRef?.current?.scrollIntoView({
        behavior: "instant",
        block: "end",
      });
    });
  };

  const onSubmit = async (query, file) => {
    if (accessToken) {
      setUserQuery("");
      setAttachedDoc({
        query: query,
        name: fileName,
      });

      setEnteredQuery(query);

      setInteractions(prevInteractions => [
        ...prevInteractions,
        {
          role: "system",
          content: query,
          fileName: file || null,
        },
      ]);

      setTimeout(() => {
        const chatContainer = lastRef.current?.parentElement;
        if (chatContainer) {
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }
      }, 0);

      let PDF = null;
      if (attachment) {
        console.log("attachment", attachment);
        const formData = new FormData();
        formData.append("fileId", 1);
        formData.append("question", query);
        formData.append("resultId", selectedChatId || 0);
        formData.append("file", attachment);

        setIsLoading(true);
        setUploading(true);

        await questionWithPDFFetch({
          data: formData,
        });

        setUploading(false);
      } else {
        setIsLoading(true);

        await questionFetch({
          data: {
            question: query,
            chatId: selectedChatId || 0,
          },
        });
      }

      setIsLoading(false);
      setSelectedPdfName("");
    } else {
      toast.error('Please login to continue');
      navigate(routeConstant.signin);
    }
  };

  useEffect(() => {
    if (questionData?.data?.text) {
      setSelectedChatId(questionData.data.chatId);
      setInteractions((prevInteractions) => [
        ...prevInteractions,
        {
          role: "assistant",
          content: questionData.data.text,
        },
      ]);
      fetchDrawerChats();

      scrollToBottom();
    }
  }, [questionData]);

  useEffect(() => {
    if (questionWithPDFData?.data?.text) {
      setSelectedChatId(questionWithPDFData.data.chatId);
      setInteractions((prevInteractions) => [
        ...prevInteractions,
        {
          role: "assistant",
          content: questionWithPDFData.data.text,
        },
      ]);
      fetchDrawerChats();

      scrollToBottom();
      setAttachment(null);
    }
  }, [questionWithPDFData]);

  useEffect(() => {
    // Update the lastInteractionIndexRef after each render
    lastInteractionIndexRef.current = interactions.length - 1;
  }, [interactions]);

  useEffect(() => {
    model !== 0 && setIsUpgradePlanModalOpen(true);
  }, [model]);

  const isMobile = useMediaQuery('(max-width:767px)');
  const [isDrawerTemporaryOpen, setIsDrawerTemporaryOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerTemporaryOpen(!isDrawerTemporaryOpen);
  };

  const handleChatItemClick = async (chatId) => {
    setSelectedChatId(chatId);
    setIsChatLoading(true);
    setInteractions([]);

    await fetchChatQuestions({
      data: { chatId },
    });

    fetchDrawerChats();
    setIsChatLoading(false);

    // Add scroll to bottom after chat loads
    setTimeout(() => {
      lastRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 100);
  };

  const handleNewChat = () => {
    setInteractions([]);
    setSelectedChatId(0);
  };

  const handleChatDelete = (chatId) => {
    setDeleteConfirmation({
      open: true,
      chatId
    });
  };

  const confirmDelete = async () => {
    const chatId = deleteConfirmation.chatId;
    setDeleteConfirmation({ open: false, chatId: null });

    try {
      await deleteChat({
        data: {
          tableId: 1,
          resultId: chatId
        }
      });

      // Refresh the chat list after deletion
      fetchDrawerChats();

      // If the deleted chat was selected, clear the interactions and reset selectedChatId
      if (chatId === selectedChatId) {
        setInteractions([]);
        setSelectedChatId(0);
      }

      toast.success('Chat deleted successfully');
    } catch (error) {
      toast.error('Failed to delete chat');
    }
  };

  const shouldAutoScroll = () => {
    const container = lastRef.current?.parentElement;
    if (!container) return false;

    const threshold = 100; // pixels from bottom
    return container.scrollHeight - container.scrollTop - container.clientHeight < threshold;
  };

  useEffect(() => {
    // Scroll to the bottom whenever messages array changes
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [userQuery]);

  return (
    <Box className={`flex h-[100vh] w-full bg-gray-900 text-white ${isMobile ? 'flex-col' : 'flex-row'}`}>
      <Box className={`flex items-center justify-between w-full px-5 py-2 ${isMobile ? 'flex-row' : 'hidden'}`}>
        <div className="flex-1">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <ChatModelDropdown model={model} setModel={setModel} />
        {accessToken || user ? (
          <LoadingButton
            loadingPosition="start"
            loading={isLoggingOut}
            className="!bg-slate-800 !border-black !border !rounded-3xl !py-2 !px-6 !text-[16px] !font-bold !flex !justify-center !items-center hover:!bg-slate-700 !text-white !capitalize"
            onClick={async () => {
              setIsLoggingOut(true);
              await logoutFetch();
              setAccessToken(null);
              Cookies.remove('accessToken');
              setIsLoggingOut(false);
              window.location.reload();
            }}
          >
            Log Out
          </LoadingButton>
        ) : (
          <Box className="flex-1 flex gap-[8px] items-center justify-end">
            <CustomLink
              link={routeConstant.signin}
              bg="!bg-slate-800"
              border="!border-black"
            >
              Log In
            </CustomLink>
            {!isMobile && (
              <CustomLink
                link={routeConstant.signup}
                bg="!bg-transparent"
                border="!border-slate-800"
              >
                Sign Up
              </CustomLink>
            )}
          </Box>
        )}
      </Box>
      {isMobile && (
        <Drawer
          anchor="left"
          open={isDrawerTemporaryOpen}
          onClose={handleDrawerToggle}
        >
          <Box className="px-5 py-4 w-[75vw] h-full bg-slate-800 flex flex-col gap-6">
            <Box className="flex justify-between">
              <DrawerButtons
                setIsDrawerOpen={setIsDrawerOpen}
                isDrawerOpen={isDrawerOpen}
                onNewChat={handleNewChat}
              />
            </Box>
            <Button
              onClick={handleNewChat}
              className="flex justify-center !bg-gray-900 !text-white !text-[14px] !font-extrabold !capitalize py-3 px-3 rounded-lg cursor-pointer hover:!bg-slate-700"
            >
              New Chat
            </Button>
            <Button
              onClick={() => setIsUpgradePlanModalOpen(true)}
              className="flex justify-center !bg-gray-900 !text-white !text-[14px] !font-extrabold !capitalize py-3 px-3 rounded-lg cursor-pointer hover:!bg-slate-700"
            >
              Upgrade your plan
            </Button>
            {isDrawerChatsLoading ? (
              <Box className="flex flex-col gap-2 px-2">
                {[1, 2, 3].map((i) => (
                  <Box
                    key={i}
                    className="h-12 rounded-lg bg-slate-700 animate-pulse"
                  />
                ))}
              </Box>
            ) : (
              <DrawerChatsList
                chatList={drawerChatsData?.data || []}
                onChatItemClick={handleChatItemClick}
                onChatDelete={handleChatDelete}
              />
            )}
          </Box>
        </Drawer>
      )}
      {!isMobile && (
        <Box
          className={`px-5 py-4 ${isMobile ? 'w-full' : 'w-[22%]'} h-full overflow-y-auto custom-scrollbar bg-slate-800 ${isDrawerOpen ? "flex" : "hidden"} flex-col gap-6`}
        >
          <Box className="flex justify-between">
            <DrawerButtons
              setIsDrawerOpen={setIsDrawerOpen}
              isDrawerOpen={isDrawerOpen}
              onNewChat={handleNewChat}
            />
          </Box>
          <Box className="flex flex-col w-full gap-3">
            <Button
              onClick={handleNewChat}
              className="flex justify-center !bg-gray-900 !text-white !text-[14px] !font-extrabold !capitalize py-3 px-3 rounded-lg cursor-pointer hover:!bg-slate-700"
            >
              New Chat
            </Button>
            <Button
              onClick={() => setIsUpgradePlanModalOpen(true)}
              className="flex justify-center !bg-gray-900 !text-white !text-[14px] !font-extrabold !capitalize py-3 px-3 rounded-lg cursor-pointer hover:!bg-slate-700"
            >
              Upgrade your plan
            </Button>
          </Box>
          {isDrawerChatsLoading ? (
            <Box className="flex flex-col gap-2 px-2">
              {[1, 2, 3].map((i) => (
                <Box
                  key={i}
                  className="h-12 rounded-lg bg-slate-700 animate-pulse"
                />
              ))}
            </Box>
          ) : (
            <DrawerChatsList
              chatList={drawerChatsData?.data || []}
              onChatItemClick={handleChatItemClick}
              onChatDelete={handleChatDelete}
            />
          )}
        </Box>
      )}
      <Box className="flex items-center flex-1 h-full">
        <Box className="relative flex flex-col justify-between w-full h-full gap-10 px-5 pt-3 pb-4 bg-gray-900">
          <Box className={`h-[6%] hidden md:flex justify-between items-center w-full ${isMobile ? 'flex-col gap-4' : ''}`}>
            <Box className="flex gap-4 mb-[1px]">
              {!isDrawerOpen && !isMobile && (
                <DrawerButtons
                  setIsDrawerOpen={setIsDrawerOpen}
                  isDrawerOpen={isDrawerOpen}
                  onNewChat={handleNewChat}
                />
              )}
              <ChatModelDropdown model={model} setModel={setModel} />
            </Box>
            {accessToken || user ? (
              <LoadingButton
                loadingPosition="start"
                // startIcon={<CircularProgress color="inherit" size={16} />}
                loading={isLoggingOut}
                className="!bg-slate-800 !border-black !border !rounded-3xl !py-2 !px-6 !text-[16px] !font-bold !flex !justify-center !items-center  hover:!bg-slate-700 !text-white !capitalize"
                onClick={async () => {
                  setIsLoggingOut(true);
                  await logoutFetch();
                  setAccessToken(null);
                  Cookies.remove('accessToken');
                  setIsLoggingOut(false);
                  window.location.reload();
                }}
              >
                Log Out
              </LoadingButton>
            ) : (
              <Box className="flex gap-[8px] items-center">
                <CustomLink
                  link={routeConstant.signin}
                  bg="!bg-slate-800"
                  border="!border-black"
                >
                  Log In
                </CustomLink>
                <CustomLink
                  link={routeConstant.signup}
                  bg="!bg-transparent"
                  border="!border-slate-800"
                >
                  Sign Up
                </CustomLink>
              </Box>
            )}
          </Box>
          {!(interactions.length > 0) && (
            <Box className="!text-[65px] !font-bold flex justify-center gap-5 !text-white text-center pt-[100px]">
              <img src={Logo} alt="logo" width={isMobile ? 200 : 300} />
            </Box>
          )}
          <Box
            className={`${isMobile ? 'w-full' : 'w-[70%]'} h-[100%] overflow-y-auto custom-scrollbar mx-auto flex flex-col gap-4`}
            sx={{
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 200px)', // Adjust this value based on your header/footer heights
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#1e293b',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#475569',
                borderRadius: '4px',
              },
            }}
          >
            {isChatLoading ? (
              <Box className="flex flex-col gap-4 px-2">
                <Box>
                  <Box className="flex justify-end w-full mb-4">
                    <Box className="h-12 bg-slate-700 rounded-3xl animate-pulse max-w-lg w-[300px]" />
                  </Box>
                  <Box className="flex items-start gap-5">
                    <Box className="flex justify-start w-full">
                      <Box className="h-12 bg-slate-700 rounded-3xl animate-pulse max-w-lg w-[300px]" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : interactions.length > 0 ? (
              interactions.map((item, index) => {
                const isLastInteraction = index === interactions.length - 1;

                if (item?.role === "system") {
                  // console.log("item", item);

                  return (
                    <Box key={index} className="flex flex-col gap-2 text-white">
                      {item.fileName && (
                        <Box className="flex justify-end w-full">
                          <Box className="flex items-center gap-2 !py-3 !bg-transparent border border-slate-700 !rounded-xl !px-5 !text-[14px] !text-white max-w-lg">
                            <Box className="!p-[5px] !rounded-lg !bg-slate-700">
                              <PictureAsPdfIcon
                                fontSize="medium"
                                className="text-white "
                              />
                            </Box>
                            {item.fileName.name || item.fileName}
                          </Box>
                        </Box>
                      )}
                      <Box className="flex justify-end w-full">
                        <Typography className="!py-2 !bg-slate-700 !rounded-3xl !px-5 !text-[14px] !text-white max-w-lg">
                          {item?.content}
                        </Typography>
                      </Box>
                    </Box>
                  );
                } else {
                  return (
                    <Box key={index} className="flex items-start gap-5">
                      <Box className="border bg-white rounded-3xl border-white p-[1px] mt-1">
                        <img
                          src={chatIcon}
                          alt="evatt-logo"
                          className="h-6 w-7"
                        />
                      </Box>
                      <Box className="flex justify-start w-full">
                        <Typography
                          ref={lastRef}
                          className="!py-2 !bg-slate-700 !rounded-3xl !px-5 !text-[14px] !text-white max-w-lg break-words"
                        >
                          {isLastInteraction && index > lastInteractionIndexRef.current && !item.fromHistory ? (
                            <Typewriter
                              options={{
                                delay: 0,
                                cursor: "|",
                                autoStart: true,
                              }}
                              onInit={(typewriter) => {
                                const words = item?.content.split(" ");
                                let typeChain = typewriter;

                                words.forEach((word, index) => {
                                  typeChain = typeChain
                                    .typeString(word + " ")
                                    .pauseFor(50)
                                    .callFunction(() => {
                                      if (shouldAutoScroll()) {
                                        lastRef?.current?.scrollIntoView({
                                          behavior: "smooth",
                                          block: "end",
                                        });
                                      }
                                    });
                                });

                                typeChain
                                  .callFunction(() => {
                                    const typewriterElement = document.querySelector(
                                      ".Typewriter__cursor"
                                    );
                                    if (typewriterElement) {
                                      typewriterElement.style.display = "none";
                                    }
                                  })
                                  .start();
                              }}
                            />
                          ) : (
                            item?.content
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  );
                }
              })
            ) : (
              <Box className="flex items-center justify-center px-5">
                <Typography className="!text-white !text-[24px] !font-bold">
                  <Typewriter
                    options={{
                      delay: 0,
                      cursor: "|",
                      autoStart: true,
                    }}
                    onInit={(typewriter) => {
                      const words = "What can I help with?".split(" ");
                      let typeChain = typewriter;
                      words.forEach((word, index) => {
                        typeChain = typeChain
                          .typeString(word + " ")
                          .pauseFor(100);
                      });

                      typeChain
                        .callFunction(() => {
                          const typewriterElement = document.querySelector(
                            ".Typewriter__cursor"
                          );
                          if (typewriterElement) {
                            typewriterElement.style.display = "none";
                          }
                        })
                        .start();
                    }}
                  />
                </Typography>
              </Box>
            )}
            {uploading && (
              <Box className="flex items-start gap-5">
                <Box className="w-[30px] border bg-white rounded-3xl border-white p-[1px] mt-1">
                  <img src={chatIcon} alt="evatt-logo" className="h-6 w-7" />
                </Box>
                <Typography className="!py-3.5 !bg-slate-700 !rounded-3xl !px-5 !text-[14px] !text-white">
                  Uploading...
                </Typography>
              </Box>
            )}
            {isLoading && (
              <Box className="flex items-start gap-5">
                <Box className="w-[30px] border bg-white rounded-3xl border-white p-[1px] mt-1">
                  <img src={chatIcon} alt="evatt-logo" className="h-6 w-7" />
                </Box>
                <Typography className="!py-3.5 !bg-slate-700 !rounded-3xl !px-5 !text-[14px] !text-white">
                  <div className="flex flex-row gap-1">
                    <div className="w-2 h-2 bg-white rounded-full animate-bounce"></div>
                    <div className="w-2 h-2 rounded-full bg-white animate-bounce [animation-delay:-.3s]"></div>
                    <div className="w-2 h-2 rounded-full bg-white animate-bounce [animation-delay:-.5s]"></div>
                  </div>
                </Typography>
              </Box>
            )}
            <div ref={bottomRef} />
          </Box>

          <Box
            className={`mx-auto ${interactions.length > 0 ? "bottom-3" : "bottom-[50%]"} ${isMobile ? 'w-[calc(100vw-40px)]' : 'w-[70%]'}`}
          >
            <ChatInput
              userQuery={userQuery}
              setUserQuery={setUserQuery}
              onSubmit={onSubmit}
              attachment={attachment}
              setAttachment={setAttachment}
              isLoading={isLoading}
              selectedPdfName={selectedPdfName}
              setSelectedPdfName={setSelectedPdfName}
              setFileSizeWarning={setFileSizeWarning}
            />
          </Box>
        </Box>
      </Box>
      <UpgradePlanModal
        isUpgradePlanModalOpen={isUpgradePlanModalOpen}
        setIsUpgradePlanModalOpen={setIsUpgradePlanModalOpen}
      />
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, chatId: null })}
        PaperProps={{
          style: {
            borderRadius: '12px',
            backgroundColor: '#1e293b', // slate-800
            color: 'white',
            minWidth: '300px'
          }
        }}
      >
        <DialogTitle className="text-center">Delete Confirmation</DialogTitle>
        <DialogContent>
          <p className="text-center">Are you sure you want to delete this chat?</p>
        </DialogContent>
        <DialogActions className="justify-center pb-4">
          <Button
            onClick={() => setDeleteConfirmation({ open: false, chatId: null })}
            className="!px-6 !py-2 !text-gray-300 !border !border-gray-600 hover:!bg-gray-700"
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            className="!px-6 !py-2 !bg-red-500 !text-white hover:!bg-red-600"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={fileSizeWarning}
        onClose={() => setFileSizeWarning(false)}
        PaperProps={{
          style: {
            borderRadius: '12px',
            backgroundColor: '#1e293b', // slate-800
            color: 'white',
            minWidth: '300px'
          }
        }}
      >
        <DialogTitle className="text-center">File Size Warning!</DialogTitle>
        <DialogContent>
          <div className="flex flex-col items-center w-full">
            <WarningIcon style={{ fontSize: '50px' }} />
            <p className="w-full mt-3 text-center">File size must be lower than 5MB</p>
          </div>
        </DialogContent>
        <DialogActions className="justify-center pb-4">
          <Button
            onClick={() => setFileSizeWarning(false)}
            className="!px-6 !py-2 !text-gray-300 !border !border-gray-600 hover:!bg-gray-700"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
