import { Route, Routes } from "react-router-dom";
import routeConstant from "./utils/routeConstant";
import Dashboard from "./pages/Dashboard";
import SignIn from "./pages/SignIn";
import ForgetPassword from "./pages/ForgetPassword";
import SignUp from "./pages/SignUp";
import { UserProvider } from "./providers/userProvider";
import { Toaster } from "react-hot-toast";
import AccountActivate from "./pages/AccountActivate";
import BookingSuccess from "./pages/BookingSuccess";

const App = () => {
  return (
    <UserProvider>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path={routeConstant?.signin} element={<SignIn />} />
        <Route path={routeConstant?.forgetPassword} element={<ForgetPassword />} />
        <Route path={routeConstant?.signup} element={<SignUp />} />
        <Route path={routeConstant?.dashboard} element={<Dashboard />} />
        <Route path={routeConstant?.accountActivate} element={<AccountActivate />} />
        <Route path={routeConstant?.bookingSuccess} element={<BookingSuccess />} />
      </Routes>
    </UserProvider>
  );
};

export default App;
