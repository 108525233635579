import { Box, Button, Typography, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import routeConstant from "../utils/routeConstant";
import Logo from "../assets/image.png";
import OTPModal from "../components/OTPModal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { signUpValidationSchema } from "../utils/validationSchema";
import { generateOTP } from "../utils/generateOTP";
import useFetch from "../api/HttpRequest";

const SignUp = () => {
  const [currentOTP, setCurrentOTP] = useState();
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(signUpValidationSchema),
  });

  const { data: registerResponse, fetchData: registerFetch } = useFetch('/user/register', {
    method: 'POST',
    data: null, // This will be set dynamically
    silent: false,
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);
    const OTP = generateOTP();
    setCurrentOTP(OTP);
    const payload = {
      firstName: data.name,
      email: data.email,
      password: data.password,
      // subject: "",
      // otp: OTP,
    };
    try {
      await registerFetch({ data: payload });
    } catch (err) {
      console.error("An error occurred during signup:", err);
      setError("An error occurred during signup. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (registerResponse?.status) {
      setIsRegistered(true);
    }
  }, [registerResponse]);

  return (
    <Box className="flex flex-col items-center min-h-screen gap-20 py-10 bg-gray-900">
      <Link
        to={routeConstant.dashboard}
        className="!text-[50px] !font-bold !text-white"
      >
        <img src={Logo} alt="logo" width={250} />
      </Link>
      {isRegistered ? (
        <Typography className="!text-center !text-[25px] !font-bold !text-green-500 w-full lg:w-1/2">
          Successfully registered, check your email inbox to activate your account.
        </Typography>
      ) : (
        <Box className="flex flex-col gap-4 w-full px-5 lg:w-1/2 xl:w-[30%]">
          <Box>
            <Typography className="!text-[22px] !font-semibold !text-white">
              Create Account
            </Typography>
            <Typography className="!text-[12px] !font-bold !text-gray-400">
              Let's get started by filling out the form below.
            </Typography>
          </Box>

          <TextField
            type="string"
            placeholder="Name"
            {...register("name")}
            error={!!errors?.name}
            helperText={errors?.name?.message?.toString()}
            variant="outlined"
            margin="normal"
            fullWidth
            className="!bg-transparent !text-white !outline-none !m-0 !p-0"
            slotProps={{
              input: {
                className:
                  "!text-white !font-bold !border !border-gray-600 !rounded-xl",
              },
              select: {
                className: "focus:ring-violet-300",
              },
            }}
          />

          <TextField
            type="string"
            placeholder="Email"
            {...register("email")}
            error={!!errors?.email}
            helperText={errors?.email?.message?.toString()}
            variant="outlined"
            margin="normal"
            fullWidth
            className="!bg-transparent !text-white !outline-none !m-0 !p-0"
            slotProps={{
              input: {
                className:
                  "!text-white !font-bold !border !border-gray-600 !rounded-xl",
              },
              select: {
                className: "focus:ring-violet-300",
              },
            }}
          />

          <TextField
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            {...register("password")}
            error={!!errors?.password}
            helperText={errors?.password?.message?.toString()}
            variant="outlined"
            margin="normal"
            fullWidth
            className="!bg-transparent !text-white !outline-none !m-0 !p-0"
            slotProps={{
              input: {
                endAdornment: (
                  <Box>
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <VisibilityIcon className="text-white cursor-pointer" />
                      ) : (
                        <VisibilityOffIcon className="text-white cursor-pointer" />
                      )}
                    </IconButton>
                  </Box>
                ),
                className:
                  "!text-white !font-bold !border !border-gray-600 !rounded-xl",
              },
            }}
          />

          <Box className="flex justify-center">
            <Button
              type="submit"
              className="!border !border-black !rounded-xl !py-2 !px-20 !text-[16px] !font-bold !flex !justify-center !items-center !bg-[#00A67E] !text-white !capitalize"
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
            >
              {loading ? "Registering..." : "Register"}
            </Button>
          </Box>

          {/* Display error message */}
          {error && (
            <Typography className="!text-center !text-[12px] !font-bold !text-red-500">
              {error}
            </Typography>
          )}

          <Typography className="!text-center !text-[14px] !font-bold !text-gray-400 justify-center items-center flex gap-1">
            Already have an account?
            <Link to="/signin" className="hover:!underline">
              Sign In
            </Link>
          </Typography>

          {/* <Typography className="!text-center !text-[14px] !font-bold !text-gray-400">
              Or sign up with
            </Typography>

            <Box className="flex items-center gap-4">
              <AuthButtons type="Google" mode="signup" />
              <AuthButtons type="Apple" mode="signup" />
            </Box>
            <Box className="flex justify-center">
              <AuthButtons type="Facebook" mode="signup" />
            </Box>
            <OTPModal
              userDetails={getValues()}
              isOTPModalOpen={isOTPModalOpen}
              setIsOTPModalOpen={setIsOTPModalOpen}
              currentOTP={currentOTP}
              setCurrentOTP={setCurrentOTP}
            /> */}
        </Box>
      )}
    </Box>
  );
};

export default SignUp;
