import React, { useState, useEffect, createContext } from "react";
import useFetch from "../api/HttpRequest";
import Cookies from 'js-cookie';

export const UserContext = createContext({ user: null });

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const accessToken = Cookies.get('accessToken');
  const { data, error, responseCode, fetchData } = useFetch('/user/check', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      if (responseCode === "401" && accessToken) {
        Cookies.remove('accessToken');
        window.location.reload();
      } else if (data.status) {
        setUser(data);
      }
    } else if (error) {
      setUser(null);
    }
  }, [data, error, responseCode, accessToken]);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};
