import {
  Box,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const DrawerChatsList = ({ chatList, onChatItemClick, onChatDelete }) => {

  return (
    <Box className="flex flex-col gap-4">
      {chatList.map((item) => (
        <Box
          key={item.id}
          className="flex items-center justify-between px-3 py-3 text-white bg-gray-900 rounded-lg cursor-pointer text-nowrap"
          onClick={() => onChatItemClick(item.id)}
        >
          <Typography className="!text-[14px] !font-semibold truncate">
            {item.title}
          </Typography>
          <DeleteIcon
            fontSize="small"
            className="cursor-pointer text-[#d6d6d6]"
            onClick={(e) => {
              e.stopPropagation();
              onChatDelete(item.id);
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default DrawerChatsList;
